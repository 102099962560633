.detailHeader_container {
  background-color: #FFFFFF;
  padding: 30px 0 20px 20px;
  .detailHeader_title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h1 {
      color: #232323;
      font-size: 18px;
      font-weight: bold;
      margin-right: 10px;
    }
    p {
      width: 44px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background:rgba(255,147,0,0.15);
      border-radius:2px;
      color: #FF9300;
      font-size: 12px;
    }
  }
  .content {
    display: flex;
    .leftContent {
      width: 35%;
    }
    .rightContent {
      flex: 1;
    }
  }
}

.contentItem {
  margin-bottom: 10px;
  display: flex;
  .title {
    font-size: 14px;
    margin-right: 10px;
    color: @font-color-black;
  }
  .content {
    color: @font-color-grey;
    font-size: 14px
  }
}
