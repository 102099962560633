#returnMoneyDate{
  width: 100%
}
.salesBack-add-container{
  .ant-form-item-with-help{
    margin-bottom: 24px !important;
  }
}
.salesBack-add-prompt{
  margin-bottom: 10px;
  text-align: center;
}
.sales-back-is-payment-day{
  background: @error-color !important;
  color: #fff;
  td a, td span, td span span{
    color: #fff !important;
  }
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    background: @error-color !important;
  }
}
.sales-back-is-payment-overdue{
  background: #FF9300 !important;
  color: #fff;
  td a, td span, td span span{
    color: #fff !important;
  }
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    background: #FF9300 !important;
  }
}
.salesBackDetail-backList-status{
  table{
    td, th{
      &:last-child{
        display: none;
      }
    }
  }
}
.sendGoods-batchDelete-serial-number-title{
  border-bottom: 1px solid #efefef;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  padding-bottom: 10px;
  font-weight: bold;
}
.sendGoods-batchDelete-serial-number{
  text-align: left;
  span{
    display: inline-block;
    width: 33.333%;
    padding-bottom: 5px;
    color: #ababab;
  }
}
