.report-title{
  border-bottom: 1px solid #f5f5f5;
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: 10px solid #f5f5f5;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px
}

.report-flowReport-container{
  background-color: #fff;
  min-height: 86vh;
  td{
    white-space: nowrap;
  }
}

.report-flowReport-table{
  margin: -11px -17px;
  > div{
    min-height: 25px;
    line-height: 25px;
    padding-left: 16px;
    padding-right: 16px;
    &:not(:last-child) {
      border-bottom:1px solid #e8e8e8;
    }
  }
}
.report-flowReport-table-container{
  padding: 0 20px;
  table{
    tr {
      td{
        &:nth-child(11), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16){
          background-color: tint(@primary-color, 90%);
        }
      }
      &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
      &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
      &.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
        &:nth-child(11), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16){
          background-color: tint(@primary-color, 90%);
        }
      }
    }
  }
}
.report-flowReport-salesman-container{
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #e8e8e8;
  .report-flowReport-salesman-head{
    padding: 10px;
    border: 1px solid #e8e8e8;
    font-size: 14px;
    background-color: #F1F1F1;
  }
}
.report-flowReport-export-container{
  button{
    margin-left: 20px;
  }
}
.flowReport-status-color{
  color: @primary-color;
}
.report-filter-flex{
  .report-flowReport-export-container{
    padding-bottom: 15px;
  }
}
.report-flow-action-color{
  span{
    color: @primary-color;
  }
  .report-flow-action-margin{
    margin-left: 5px;
    margin-right: 5px;
  }
}
.report-adjust-history-table{
  margin: -24px;
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 8px 16px;
  }
}

.flow-report-add-container{
  background-color: #FFF;
  padding: 20px;
}
.flow-report-btn-container{
  text-align: center;
  Button{
    margin-right: 5px;
    margin-left: 5px;
    &:first-child{
      background-color: @primary-color;
      color: #fff;
    }
  }
}
