.questionnaireResultHeader_container {
  background-color: #FFFFFF;
  padding: 20px 0 10px 20px;
  .headerListItem {
    margin-bottom: 10px;
    .headerListTitle {
      font-size: 14px;
      margin-right: 10px;
      color: @font-color-black;
    }
    .headerListContent {
      color: @font-color-grey;
      font-size: 14px;
    }
  }
}
