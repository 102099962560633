.salesBackDetail-container{
  background-color: #FFFFFF;
  padding-top: 1px;
  padding-bottom: 1px;
  .salesBackDetail-addBtn{
    button{
      background-color: @hasKol-clickToCancel-fontColor;
      color: #fff;
      border-color: @hasKol-clickToCancel-fontColor;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
  .salesBackDetail-addBtnBack{
    text-align: center;
    margin-top: 15px;
  }
}
.salesBackDetail-container-record-container{
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #e8e8e8;
  .salesBackDetail-container-record-head{
    padding: 10px;
    border: 1px solid #e8e8e8;
    font-size: 14px;
    background-color: #F1F1F1;
  }
  tbody.ant-table-tbody{
    tr:last-child{
      td{
        &:last-child{
          a, span{
            display: none;
          }
        }
        &:nth-child(7), &:nth-child(8),&:nth-child(9) {
          &:after{
            display: inline-block;
            content: '--';
          }
        }
      }
    }
  }
}
.salesBack-add-container{
  margin-bottom: -15px;
  .ui-content{
    min-height: 10px !important;
    padding: 0;
  }
}
.salesBack-register-status{
  text-align: center;
}
