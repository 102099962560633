.institution-depend-health-care{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FAFAFA;
  min-height: 76vh;
  padding: 50px;
  button{
    align-self: center;
  }
}
.institution-depend-health-care-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.institution-depend-health-care-no-content-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.institution-depend-health-care-footer{
  align-self: center;
  button{
    margin: 10px;
  }
}