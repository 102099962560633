.login-form-forgetPassword {
  display: none;
}
.ui-login-leftLogo img {
  width: 278px;
  height: auto;
  margin-top: -66px;
  margin-left: -40px;
}
.login-help-container{
  display: none;
}
.ui-login-bottomInfo-downLoad{
  display:none;
}